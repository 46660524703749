<template>
  <div>
    <titulo-contas @cadastrar="openModal" />

    <tabela :items="items" @editar="openModal" @excluir="confirmarExclusao" />

    <modal-conta
      :form="modais.contaDados"
      :exibir="modais.conta"
      @fechar="closeModal('conta')"
      @conta-criada="getContas"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';

// Services:
import ContaService from '@/common/services/conta/conta.service';

// Components:
import Tabela from './components/Tabela';
import ModalConta from './components/Modal';
import TituloContas from '@/views/financeiro/estrutura/contas/components/TituloContas';

export default {
  components: {
    TituloContas,
    Tabela,
    ModalConta,
  },
  data() {
    return {
      items: [],
      modais: {
        conta: false,
        contaDados: {},
      },
      paginacao: {
        paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 5 },
        total: 0,
      },
    };
  },
  mounted() {
    this.getContas();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getContas() {
      this.$store.dispatch(START_LOADING);
      ContaService.obterTodas(this.paginacao)
        .then(({ data }) => {
          this.items = data.itens;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('GERAL.CONFIRMAR_EXCLUSAO'),
        this.$t('GERAL.CONFIRMAR_EXCLUSAO_ITEM').formatUnicorn({
          item: this.$t('FINANCEIRO.CONTA'),
        })
      );
      respostaExclusao.isConfirmed ? this.excluir(item) : {};
    },
    excluir(item) {
      this.$store.dispatch(START_LOADING);
      ContaService.excluir(item.id)
        .then(() => {
          mensagem
            .sucesso(
              this.$t('GERAL.SUCESSO'),
              this.$t('GERAL.ITEM_DELETADO').formatUnicorn({
                item: this.$t('FINANCEIRO.CONTA'),
              })
            )
            .then((result) => {
              if (result.isDismissed || result.isConfirmed) {
                this.getContas();
              }
            });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
