<template>
  <div class="row">
    <!-- info geral: - tipoConta - data abertura - planoContaId -->
    <div class="col-12 col-md-6">
      <input-select-search
        ref="tipoConta"
        v-model="form.tipoConta"
        :label="$t(`FINANCEIRO.TIPO_CONTA`)"
        :options="opcoes.tipoContas"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-date-only-btn
        ref="dataAbertura"
        v-model="form.dataAbertura"
        :label="$t('FINANCEIRO.DATA_ABERTURA')"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="periodoFechamento"
        v-model="form.periodoFechamentoId"
        :label="$t(`FINANCEIRO.PERIODO_FECHAMENTO`)"
        :options="opcoes.periodoFechamento"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="planoContaId"
        v-model="form.planoContaId"
        :label="$t(`FINANCEIRO.PLANO_CONTA`)"
        :options="opcoes.planoContas"
        required
      />
    </div>

    <!-- banco - ag - conta - variacao -->
    <div class="col-12 col-md-6">
      <input-select-search
        ref="banco"
        v-model="form.bancoId"
        :label="$t(`FINANCEIRO.BANCO`)"
        :options="opcoes.bancos"
      />
    </div>
    <div class="col-12 col-md-6">
      <input-number
        ref="agencia"
        v-model="form.agencia"
        :label="$t('FINANCEIRO.AGENCIA')"
        :placeholder="$t('FINANCEIRO.AGENCIA')"
        :maxLength="999999"
      />
    </div>
    <div class="col-12 col-md-6">
      <input-text
        ref="numeroConta"
        v-model="form.numeroConta"
        :label="$t('FINANCEIRO.CONTA')"
        :placeholder="$t('FINANCEIRO.CONTA')"
        type="number"
        :maxLength="20"
      />
    </div>
    <div class="col-12 col-md-6">
      <input-text
        ref="variacao"
        v-model="form.variacao"
        :label="$t('FINANCEIRO.VARIAÇÃO')"
        :placeholder="$t('FINANCEIRO.VARIAÇÃO')"
        type="number"
        :maxLength="5"
      />
    </div>

    <!-- descrição -->
    <div class="col-12 col-md-12">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        type="text"
        :label="$t('FINANCEIRO.DESCRICAO')"
        :placeholder="$t('FINANCEIRO.DESCRICAO')"
        required
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import BancoService from '@/common/services/bancos/bancos.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import PlanoContaService from '@/common/services/plano-conta/plano-conta.service.js';

// Components:
import {
  InputDateOnlyBtn,
  InputNumber,
  InputText,
  InputSelectSearch,
} from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputDateOnlyBtn,
    InputNumber,
    InputText,
    InputSelectSearch,
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.getBancos();
    this.getPeriodoFechamento();
    this.getPlanoConta();
    this.getTipoConta();
    this.getStatus();
  },
  data() {
    return {
      opcoes: {
        bancos: [],
        tipoContas: [],
        periodoFechamento: [],
        planoContas: [],
        status: [],
      },
    };
  },
  methods: {
    getBancos() {
      this.$store.dispatch(START_LOADING);
      BancoService.obterTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.bancos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getStatus() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('status-financeiro')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.status = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTipoConta() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-contas')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tipoContas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          const conta = this.opcoes.tipoContas.filter((conta) => {
            return (
              conta.descricao.replace(/\s+/g, '').toLowerCase() ==
              this.form.tipoConta.replace(/\s+/g, '').toLowerCase()
            );
          });
          if (this.form.tipoConta) {
            this.form.tipoConta = conta[0].id;
          }
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getPeriodoFechamento() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('periodo-fechamento')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.periodoFechamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getPlanoConta() {
      this.$store.dispatch(START_LOADING);
      PlanoContaService.obterTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.planoContas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
