<template>
  <modal
    id="modalContas"
    titulo="FINANCEIRO.CONTAS"
    :exibir="exibir"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <form-conta :form="form" @refs="getRefs" />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import ContaService from '@/common/services/conta/conta.service';

// Components:
import Modal from '@/components/modal/Modal';
import FormConta from './Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormConta,
  },
  data() {
    return {
      refs: [],
      items: [],
      paginacao: {
        paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 5 },
        total: 0,
      },
    };
  },
  mounted() {
    this.getContas();
  },
  methods: {
    getContas() {
      ContaService.obterTodas(this.paginacao).then(({ data }) => {
        this.items = data.itens;
      });
    },
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      this.form.id ? this.editar() : this.criar();
    },
    async criar() {
      this.$store.dispatch(START_LOADING);
      const dadosFormat = {
        ...this.form,
        agencia: `${this.form.agencia}`,
        numeroConta: `${this.form.numeroConta}`,
        variacao: `${this.form.variacao}`,
        status: 1,
      };
      const contaExistente = this.items.some(
        (conta) =>
          conta.agencia === dadosFormat.agencia &&
          conta.numeroConta === dadosFormat.numeroConta
      );

      if (contaExistente) {
        mensagem.erro(this.$t('GERAL.ERRO'), 'Conta já existente!');
        this.$store.dispatch(STOP_LOADING);
      } else {
        try {
          await ContaService.criar(dadosFormat);
          mensagem
            .sucesso(this.$t('GERAL.SUCESSO'), 'Conta criada com sucesso!')
            .then((result) => {
              this.fecharModal('conta');
              if (result.isDismissed || result.isConfirmed) {
                this.$emit('conta-criada');
              }
            });
        } catch (err) {
          mensagem.showErrors(err);
        } finally {
          this.$store.dispatch(STOP_LOADING);
        }
      }
    },
    editar() {
      this.$store.dispatch(START_LOADING);
      ContaService.editar(this.form)
        .then(() => {
          this.fecharModal('conta');
          mensagem
            .sucesso(this.$t('GERAL.SUCESSO'), 'Conta editada com sucesso!')
            .then((result) => {
              if (result.isDismissed || result.isConfirmed) {
                this.$emit('conta-criada');
              }
            });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
